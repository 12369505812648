import React from "react";
import { concatClassNames as cn } from "@sys42/utils";
import { StaticImage } from "gatsby-plugin-image";

import { HighlightedText, advertedPoolSize } from "@userbrain/website-ui";

import Layout from "../layouts/layout";
import { NewBadge } from "../components/NewBadge";
import { Brands } from "../components/Brands";
import { ContentCentered } from "../components/ContentCentered";
import { GetStartedBox } from "../components/GetStartedBox";
import { HeaderOverlap } from "../components/HeaderOverlap";
import { TwoColumnFeature } from "../components/TwoColumnFeature";
import { TwoColumns } from "../components/TwoColumns";

import {
  twoColumnsAutomatedInsights,
  twoColumnsAutomatedTranscripts,
  twoColumnsMobileAppTesting,
  twoColumnsPresentationReadyReports,
} from "../helpers";

import * as helperStyles from "./helpers.module.css";
import * as styles from "./styles/features.module.css";

import imageEasySetup from "../images/featureEasySetup.jpg";
import imageInviteYourOwn from "../images/featureInviteYourOwn.jpg";
import imageAiInsights from "../images/featureAiInsights.jpg";
import imageAutomatedReports from "../images/featureAutomatedReports.jpg";
import imageShareInsights from "../images/featureShareInsights.jpg";

export default function Features() {
  return (
    <Layout
      activeMenuItem={"features"}
      title={"Features"}
      metaDescription={`User testing tool features to get you the results you need: easy setup, ${advertedPoolSize} testers, invite your own, analyze UX, collab with team, automate regular user testing and more.`}
    >
      <article>
        <HeaderOverlap
          isDisplayCta
          overline={"Features"}
          title={
            <>
              <span className={helperStyles.h1Serif}>User testing, made</span>
              &nbsp;easy.
            </>
          }
          buttonSubtext={"No credit card required"}
          staticImage={(className) => (
            <StaticImage
              src={"../images/imageVideoDetail.jpg"}
              className={className}
              alt={"User test video in detail view"}
            />
          )}
        />
        <div className={helperStyles.container}>
          <ContentCentered
            className={helperStyles.spacingLg}
            title={"Less work, easy testing and better insights"}
            titleWidth={"12em"}
          />

          <TwoColumnFeature
            className={helperStyles.spacingLg}
            col1ImageSrc={imageEasySetup}
            col1ImageAlt={"Easy test instructions editor"}
            col1Title={"Fast and easy user testing"}
            col1Text={
              "Sign up in 60 seconds, and use templates with specific tasks and question types to get started in minutes."
            }
            col1LinkTo={"/features/easy-setup"}
            col2Image={(className) => (
              <div className={cn(className, styles.imageTesterPool)}>
                <StaticImage
                  src={"../images/featureTesterPool.jpg"}
                  alt={"Tester Pool"}
                />
                <svg viewBox="0 0 50 20">
                  {/* inherit font and color and kerning/letter spacing and font-weight from the HTML node that contains the SVG*/}
                  <text
                    x="25"
                    y="16"
                    textAnchor="middle"
                    fontSize="16"
                    fill="currentColor"
                    letterSpacing={-1}
                    fontWeight="600"
                    fontKerning="auto"
                  >
                    {advertedPoolSize}
                  </text>
                </svg>
              </div>
            )}
            col2Title={`Recruit ${advertedPoolSize} qualified testers`}
            col2Text={
              "Tap into our pool of quality-assured participants. Target demographics and use screening questions to find the right users."
            }
            col2LinkTo={"/features/tester-pool"}
          />

          <TwoColumnFeature
            className={helperStyles.spacingMd}
            col1ImageSrc={imageInviteYourOwn}
            col1ImageAlt={"Old desktop computer with pixelated open envelope"}
            col1Title={"Invite your own testers"}
            col1Text={
              "Share a test link with your target audience, your customers, clients,  colleagues, friends, or family to engage them in your user testing."
            }
            col1LinkTo={"/features/invite-your-own-testers"}
            col2ImageSrc={imageAiInsights}
            col2ImageAlt={"Robotic arm making an OK sign with its fingers"}
            col2Title={"Save time with AI insights"}
            col2Text={
              "Let our AI assistant quickly mark important moments in your user tests,  saving you a lot of time and effort in getting your insights."
            }
            col2LinkTo={"/features/ai-insights"}
          />

          <TwoColumnFeature
            className={helperStyles.spacingMd}
            col1ImageSrc={imageAutomatedReports}
            col1ImageAlt={"Cards of summarized test reports"}
            col1Title={"Automated test reports"}
            col1Text={
              "Dive deep into your test results. Reports are created automatically and are designed to give you all the details."
            }
            col1LinkTo={"/features/automated-reports"}
            col2ImageSrc={imageShareInsights}
            col2ImageAlt={"Film clap board in an open position"}
            col2Title={"Share your insights"}
            col2Text={
              "Quickly capture and share key moments from user tests and make your findings more engaging and actionable with short clips."
            }
            col2LinkTo={"/features/collaborate"}
          />

          <ContentCentered
            className={helperStyles.spacingLg}
            title={"Latest Feature Updates"}
            titleWidth={"13em"}
            textContent={
              "Discover the latest upgrades powering your user testing experience."
            }
          />

          <TwoColumns
            overline={<NewBadge className={helperStyles.newBadgeInOverline} />}
            className={helperStyles.spacingLg}
            {...twoColumnsMobileAppTesting}
          />

          <TwoColumns
            overline={<NewBadge className={helperStyles.newBadgeInOverline} />}
            className={helperStyles.spacingMd}
            {...twoColumnsAutomatedInsights}
            inverted={true}
          />

          <TwoColumns
            className={helperStyles.spacingMd}
            {...twoColumnsAutomatedTranscripts}
          />

          <TwoColumns
            className={helperStyles.spacingMd}
            {...twoColumnsPresentationReadyReports}
            inverted={true}
          />

          <GetStartedBox
            title={
              <>
                Ready?{" "}
                <HighlightedText cursor>Start testing now.</HighlightedText>
              </>
            }
            className={helperStyles.spacingLg}
          />

          <Brands className={helperStyles.spacingMd} />
        </div>
      </article>
    </Layout>
  );
}
