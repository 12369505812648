import React from "react";
import { concatClassNames as cn } from "@sys42/utils";
import { AutoLink } from "../helpers/AutoLink";

import * as styles from "./styles.module.css";
import { Image } from "../Image";

export function TwoColumnFeature(props) {
  const {
    className,
    col1ImageSrc,
    col1ImageAlt,
    col1Image: col1ImageData,
    col1Title,
    col1Text,
    col1LinkTo,
    col1LinkHref,
    col2ImageSrc,
    col2ImageAlt,
    col2Image: col2ImageData,
    col2Title,
    col2Text,
    col2LinkTo,
    col2LinkHref,
    ...containerProps
  } = props;

  let col1Image = null;
  if (col1ImageData) {
    col1Image = col1ImageData;
  } else if (col1ImageSrc) {
    col1Image = { src: col1ImageSrc, alt: col1ImageAlt };
  }

  let col2Image = null;
  if (col2ImageData) {
    col2Image = col2ImageData;
  } else if (col2ImageSrc) {
    col2Image = { src: col2ImageSrc, alt: col2ImageAlt };
  }

  return (
    <div className={cn(className, styles.twoColumnFeature)} {...containerProps}>
      <div className={styles.column}>
        <AutoLink to={col1LinkTo} href={col1LinkHref}>
          <Image image={col1Image} className={styles.image} />
        </AutoLink>
        <h3 className={styles.title}>
          <AutoLink to={col1LinkTo} href={col1LinkHref}>
            {col1Title}
            <span className={styles.arrow}>&nbsp;&nbsp;</span>
          </AutoLink>
        </h3>
        <div className={styles.text}>{col1Text}</div>
      </div>
      <div className={styles.column}>
        <AutoLink to={col2LinkTo} href={col2LinkHref}>
          <Image image={col2Image} className={styles.image} />
        </AutoLink>
        <h3 className={styles.title}>
          <AutoLink to={col2LinkTo} href={col2LinkHref}>
            {col2Title}
            <span className={styles.arrow}>&nbsp;&nbsp;</span>
          </AutoLink>
        </h3>
        <div className={styles.text}>{col2Text}</div>
      </div>
    </div>
  );
}
